import React, { Fragment } from "react";
import { render } from "react-dom";

class NotFound extends React.Component {


  componentDidMount() {

  }

  render() {

    return (
      <Fragment>
      </Fragment>
    );
  }
}


export default NotFound;